// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
// export const environment = {
//   production: false
// };

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCjYSSFRGwZNgwJNMy2pg9qq05YaAtCoiA",
    authDomain: "thepicklebawlers.firebaseapp.com",
    databaseURL: "https://thepicklebawlers-default-rtdb.firebaseio.com",
    projectId: "thepicklebawlers",
    storageBucket: "thepicklebawlers.appspot.com",
    messagingSenderId: "461618564966",
    appId: "1:461618564966:web:fc977c203e3a0c261342a7",
    measurementId: "G-W14WDSLM9Q",
    vapidKey:
      "BG0mbTkivMJdySKlF2TD17VwqieQKZ0n0OCWzahjFXYt2u3VeaQsgnbMqxx7TMpX8PpQZYNWp9N3UP5b2KCx_Jg",
  },
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

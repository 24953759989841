import { Injectable } from "@angular/core";

declare let Email: any;

@Injectable({
  providedIn: "root",
})
export class TextServices {
  constructor() {}

  onText(
    sendTo: any,
    sendCC: any,
    subject: string,
    body: string,
    sentFrom: string = ""
  ) {
    // const sgMail = require("@sendgrid/mail");
    // sgMail.setApiKey(process.env.SENDGRID_API_KEY);
    // const msg = {
    //   to: sendTo, // Change to your recipient
    //   from: "juddsurfs@gmail.com", // Change to your verified sender
    //   subject: "Sending with SendGrid is Fun",
    //   text: "and easy to do anywhere, even with Node.js",
    //   html: "<strong>and easy to do anywhere, even with Node.js</strong>",
    // };
    // sgMail
    //   .send(msg)
    //   .then(() => {
    //     console.log("Email sent");
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
    // let To: string = "";
    // sendTo.forEach(async (participant: any) => {
    //   //To += `${participant.at};`;
    //   console.log("sending text to: ", participant.at);
    //   console.log("sending text body: ", body);
    //   let enabled: boolean = true;
    //   if (enabled) {
    //     setTimeout(async () => {
    //       await Email.send({
    //         Host: "smtp.elasticemail.com",
    //         Username: "juddsurfs@gmail.com",
    //         Password: "21DC8805007E6288A850C39E02975E4021CC",
    //         To: participant.at,
    //         From: "juddsurfs@gmail.com",
    //         Subject: `${subject}`,
    //         Body: `<p>${body} <br>*The Pickle Brawlers - Visit at: <br> https://thepicklebawlers.web.app/app/tabs/schedule</p><br><br><br>`,
    //       }).then((message) => {
    //         console.log("Response from Elastic:", message);
    //         if (message == "OK") console.log("Email was sent...");
    //         else console.log("Failed to send email...");
    //       });
    //     }, 1000);
    //   }
    // });
  }
}

// had to...
//
// added smtp.js
// added  "src/assets/smtp.js" to scripts in angular.json
// ref: https://elasticemail.com/account#/contacts/new/overview

//   Cell Carrier	Email Domain
// Alaska Communications	msg.acsalaska.com
// AT&T Wireless	txt.att.net
// Bell Mobility	txt.bell.ca
// Bell MTS
// text.mts.net
// Bluegrass Cellular	sms.bluecell.com
// Boost Mobile	myboostmobile.com
// Cellcom	cellcom.quiktxt.com
// Chatr	 pcs.rogers.com
// You may select Rogers Wireless for now.
// Consumer Cellular (AT&T)
// mms.att.net
// Try selecting AT&T Wireless for now.
// Consumer Cellular (T-Mobile)
// mailmymobile.net
// Cricket	mms.cricketwireless.net
// C Spire
// cspire1.com
// Digicel St. Lucia	digitextlc.com
// Eastlink Canada
// mms.eastlink.ca
// Fido	fido.ca
// Freedom Mobile
// txt.freedommobile.ca
// Must send SMS 4000 from phone to activate.
// FreeUP Mobile
// txt.att.net
// GCI
// mobile.gci.net
// Good2Go Mobile
// txt.att.net
// Google Fi
// msg.fi.google.com
// Google Voice	Not an actual carrier
// GreatCall
// vtext.com
// H2O Wireless
// txt.att.net
// IV Cellular (Illinois Valley Cellular)
// ivctext.com
// Koodo Mobile	msg.telus.com
// Metro PCS	mymetropcs.com
// Mint Mobile
// tmomail.net
// NET10	Determine NET10's carrier
// Nex-Tech	sms.nextechwireless.com
// Page Plus
// vtext.com
// PC Mobile
// mobiletxt.ca
// Pioneer Cellular	zsend.com
// Pure TalkUSA
// txt.att.net
// Republic Wireless	text.republicwireless.com
// Rogers Wireless	pcs.rogers.com
// SaskTel	sms.sasktel.com
// SIMPLE Mobile
// mmst5.tracfone.com
// Solo Mobile
// txt.bell.ca
// Please select Bell Mobility.
// Spectrum Mobile
// vtext.com
// Sprint	messaging.sprintpcs.com
// Straight Talk	Determine Straight Talk's carrier
// STRATA Networks
// rinasms.com
// Telus Mobility	msg.telus.com
// Ting (CDMA devices)
// message.ting.com
// Ting (GSM devices)
// tmomail.net
// T-Mobile	tmomail.net
// Tracfone	Determine Tracfone's carrier
// Twigby
// messaging.sprintpcs.com
// Ultra Mobile
// mailmymobile.net
// Union Wireless
// mms.unionwireless.com
// US Cellular	email.uscc.net
// Verizon	vtext.com
// Viaero	viaerosms.com
// Virgin Mobile	vmobl.com
// Virgin Mobile Canada	vmobile.ca
// Xfinity Mobile	vtext.com

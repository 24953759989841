<ion-content>
  <form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="submitForm()">
    <ion-item>
      <ion-label position="floating">*Title</ion-label>
      <ion-input type="text" formControlName="title"></ion-input>
    </ion-item>

    <ion-item style="padding-top: 10px" lines="none">
      <ion-button
        style="padding-left: 20px"
        type="submit"
        [disabled]="!formGroup.valid"
      >
        <ion-label>Save</ion-label>
      </ion-button>
      <ion-button (click)="onClose()">
        <ion-label>Cancel</ion-label>
      </ion-button>
    </ion-item>
  </form>
</ion-content>

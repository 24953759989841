// Angular Modules
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Storage } from "@ionic/storage";
import { HelpersService } from "./helpers.service";

@Injectable({
  providedIn: "root",
})
export class GroupMeHttpService {
  private url: string = "https://api.groupme.com/v3";
  public token: string = null; //"Ztjg4Vo4kTZyVeroJUnuBGI3tMPfFAv783H0Z73a"; //Ztjg4Vo4kTZyVeroJUnuBGI3tMPfFAv783H0Z73a    OmvlzvWqzG3wCF7DO5fDqXtZovcgHpzN68yX5GQj

  constructor(
    // Angular Modules
    private http: HttpClient,
    public router: Router,
    public storage: Storage,
    private helpers: HelpersService
  ) {}

  // public login(data: any, path: string, options?: any) {
  //   return this.http.post(path, data);
  // }

  public createGroup(data: any, path: string, options?: any) {
    let tokenString: string = "?token=" + this.token;
    console.log(
      `createGroup() : post: (${this.url + path + tokenString}, ${data} )`
    );
    return this.http.post(this.url + path + tokenString, data);
  }

  public addMember(data: any, path: string, options?: any) {
    let tokenString: string = "?token=" + this.token;
    console.log(
      `addMember() : post: (${this.url + path + tokenString}, ${data} )`
    );
    return this.http.post(this.url + path + tokenString, data);
  }

  public createMsg(data: any, path: string, options?: any) {
    let tokenString: string = "?token=" + this.token;
    console.log(
      `createMsg() : post: (${this.url + path + tokenString}, ${data} )`
    );
    return this.http.post(this.url + path + tokenString, data);
  }

  public getMsgs(data: any, path: string, options?: any) {
    let tokenString: string = "?token=" + this.token;
    console.log(
      `getMsgs() : post: (${this.url + path + tokenString}, ${data} )`
    );
    return this.http.get(this.url + path + tokenString, data);
  }

  // helpers
  //
  // private get(options?: any) {
  //   return this.http.get(this.url, options);
  // }
  // private post(data: any, options?: any) {
  //   return this.http.post(this.url, data, options);
  // }
  // private put(data: any, options?: any) {
  //   return this.http.put(this.url, data, options);
  // }
  // private delete(options?: any) {
  //   return this.http.delete(this.url, options);
  // }
}

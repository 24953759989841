import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";

import { AlertController, ToastController } from "@ionic/angular";
import { TextServices } from "../../providers/textServices";
import { UserData } from "../../providers/user-data";
import { GroupMeHttpService } from "../../services/groupMe.service";
import { DateTimeOptions } from "../../shared/ct-date-selector/ct-date-time/ct-date-time.component";
import { CtDatePipe } from "../../shared/pipes/ct-date.pipe";
import { Guid } from "guid-typescript";

@Component({
  selector: "page-test",
  templateUrl: "test.html",
  styleUrls: ["./test.scss"],
})
export class TestPage implements OnInit {
  username: string;
  dateTimeOptions: DateTimeOptions = new DateTimeOptions();
  group_id: string = "";
  result: any = "";
  messages: any;

  formGrpLogin: FormGroup = this.formBuilder.group({
    //startDate: new FormControl(undefined, Validators.required),
    //number: ["9043866129@mms.att.net"],
    user: ["9043866129", Validators.required],
  });

  formGrpCreateGroup: FormGroup = this.formBuilder.group({
    //startDate: new FormControl(undefined, Validators.required),
    //number: ["9043866129@mms.att.net"],
    groupName: ["Group Test 1", Validators.required],
  });

  formGrpAddMember: FormGroup = this.formBuilder.group({
    groupId: ["86904022", Validators.required],
    nickName: ["Maria", Validators.required],
    phoneNumber: ["9045366129", Validators.required],
    email: ["maria.williams@comcast.net", Validators.required],
    userId: "8890244",
  });

  formGrpSendMsg: FormGroup = this.formBuilder.group({
    groupId: ["86904022", Validators.required],
    msg: ["Send message 1", Validators.required],
  });

  formGrpGetMsgs: FormGroup = this.formBuilder.group({
    groupId: ["86904022", Validators.required],
  });

  formGrpSendGrid: FormGroup = this.formBuilder.group({
    email: ["juddsurfs@gmail.com", Validators.required],
    body: ["test body", Validators.required],
  });

  constructor(
    public alertCtrl: AlertController,
    public router: Router,
    public userData: UserData,
    private formBuilder: FormBuilder,
    public textServices: TextServices,
    public ctDatePipe: CtDatePipe,
    public groupMeHttpService: GroupMeHttpService,
    public toastCtrl: ToastController
  ) {}

  ngOnInit(): void {
    console.log("this.dateTimeOptions", this.dateTimeOptions);

    //if (!this.groupMeHttpService.token) this.submitFormLogin();
  }

  //
  // todo: use sendgrid instead.
  // https://app.sendgrid.com/guide/integrate/langs/nodejs
  async submitFormGrpSendGrid() {
    console.log("TestPage: emal to send grid");

    try {
      let participantsNotificatonsList = [];
      participantsNotificatonsList.push({
        at: this.formGrpSendGrid.value.email,
      });

      this.textServices.onText(
        participantsNotificatonsList,
        "",
        "test",
        this.formGrpSendGrid.value.body
      );
    } catch (error) {
      console.error("error calling onText(): ", error);
    }
  }

  async submitFormLogin() {
    console.log("TestPage: redirecting to oauth.groupme.com");
    window.location.href =
      "https://oauth.groupme.com/oauth/authorize?client_id=p7LdHMyRQpj4EnsWIKUN7gkhC4dyiRgmQiHn4NR6j8yMCnwP";
  }

  async handleErrors(data) {
    if (data?.meta?.code == 401) {
      console.log("TestPage|handleErrors: redirecting to oauth.groupme.com");
      window.location.href =
        "https://oauth.groupme.com/oauth/authorize?client_id=p7LdHMyRQpj4EnsWIKUN7gkhC4dyiRgmQiHn4NR6j8yMCnwP";
    } else {
      const toast = await this.toastCtrl.create({
        message: "Error(s): " + data?.meta?.errors[0],
        duration: 2000,
        position: "top",
        color: "error",
      });
      await toast.present();
    }
  }

  async submitFormCreateGroup() {
    // test date control..
    //
    let options: any = {};
    let path: string = "/groups";
    let data: any = {
      name: this.formGrpCreateGroup.value.groupName,
      share: true,
      image_url: "https://i.groupme.com/" + Guid.create(),
    };
    console.log("submitFormCreateGroup data:", data);
    var result = this.groupMeHttpService
      .createGroup(data, path, options)
      .subscribe((data: any) => {
        console.log("submitFormCreateGroup http response: ", data);
        if (data?.meta?.code < 200 && data?.meta?.code >= 300) {
          console.error("GetMsgs() error:", data?.meta);
          this.handleErrors(data?.meta);
          return;
        }
        this.group_id = data.response.group_id;

        this.formGrpAddMember.get("groupId").setValue(this.group_id);

        this.result = "group_id: " + this.group_id;
      });
  }

  async submitFormAddMember() {
    // test date control..
    //
    let options: any = {};
    //let path: string = "/groups";
    let path: string = `/groups/${this.formGrpAddMember.value.groupId}/members/add`;
    let data: any = {
      members: [
        {
          nickname: `${this.formGrpAddMember.value.nickName}`,
          phone_number: `${this.formGrpAddMember.value.phoneNumber}`,
          email: `${this.formGrpAddMember.value.email}`,
          user_id: `${this.formGrpAddMember.value.userId}`,
        },
      ],
    };
    // {
    //   name: this.formGrpCreateGroup.value.groupName,
    //   share: true,
    //   image_url: "https://i.groupme.com/" + Guid.create(),
    // };
    console.log("submitFormAddMember data:", data);
    var result = this.groupMeHttpService
      .addMember(data, path, options)
      .subscribe((data: any) => {
        //this.result = "group_id: " + data.response.group_id;
        console.log("submitFormAddMember http response: ", data);
        if (data?.meta?.code < 200 && data?.meta?.code >= 300) {
          console.error("GetMsgs() error:", data?.meta);
          this.handleErrors(data?.meta);
          return;
        }
        this.result = "response: " + data.meta.code;
      });
  }

  async submitFormSendMsg() {
    // test date control..
    //
    let options: any = {};
    let path: string = `/groups/${this.formGrpSendMsg.value.groupId}/messages`;

    let quid = Guid.create();
    let data: any = {
      message: {
        source_guid: `${quid}`,
        text: this.formGrpSendMsg.value.msg,
      },
    };

    console.log("submitFormSendMsg data:", data);
    var result = this.groupMeHttpService
      .createMsg(data, path, options)
      .subscribe((data: any) => {
        console.log("submitFormSendMsg http response: ", data);
        if (data?.meta?.code < 200 && data?.meta?.code >= 300) {
          console.error("GetMsgs() error:", data?.meta);
          this.handleErrors(data?.meta);
          return;
        }
        this.result = "response code: " + data.meta.code;
      });
  }

  async submitFormGetMsgs() {
    // test date control..
    //
    let options: any = {};
    let path: string = `/groups/${this.formGrpGetMsgs.value.groupId}/messages`;

    let quid = Guid.create();

    var result = this.groupMeHttpService
      .getMsgs("", path, options)
      .subscribe((data: any) => {
        console.log("submitFormGetMsgs http response: ", data);
        if (data?.meta?.code < 200 && data?.meta?.code >= 300) {
          console.error("GetMsgs() error:", data?.meta);
          this.handleErrors(data?.meta);
          return;
        }
        this.result = "response code: " + data.meta.code;
        this.messages = data.response.messages;
      });
  }
}

//
//
//ngInit
// this.dateTimeOptions.presentation = "date-time";

// let now = new Date();
// now.setHours(now.getHours());
// now.setMinutes(0);

// this.dateTimeOptions.min = this.ctDatePipe.transform(
//   now,
//   "yyyy-MM-ddTHH:mm:ss"
// );

// console.log("this.dateTimeOptions after:", this.dateTimeOptions);

//
// submit form
// text test below.. .
//

// let number = this.formGrp.value.number;
// console.log("number: ", number);

// let participantsNotificatonsList = [];
// participantsNotificatonsList.push({ at: number });

// let body: string = ` body for text to ${number} `;

// try {
//   this.textServices.onText(
//     participantsNotificatonsList,
//     "",
//     "Event Created",
//     body
//   );
// } catch (error) {
//   console.error("error calling onText(): ", error);
// }

<ion-header>
  <ion-toolbar>
    <ion-title>Test Page</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <h3>Email testing sendgrid</h3>
  <form [formGroup]="formGrpSendGrid" (ngSubmit)="submitFormGrpSendGrid()">
    <h5>email</h5>
    <ion-item>
      <ion-label position="floating">email</ion-label>

      <ion-input type="text" formControlName="email"></ion-input>

      <ion-label position="floating">body</ion-label>

      <ion-input type="text" formControlName="body"></ion-input>
      <ion-button type="submit">submit</ion-button>
    </ion-item>
  </form>

  <h3>Group Me testing</h3>

  <form [formGroup]="formGrpLogin" (ngSubmit)="submitFormLogin()">
    <h5>login</h5>
    <ion-item>
      <ion-label position="floating">user</ion-label>

      <ion-input type="text" formControlName="user"></ion-input>
      <!-- <ion-button type="submit">submit</ion-button> -->
      <a
        href="https://oauth.groupme.com/oauth/authorize?client_id=p7LdHMyRQpj4EnsWIKUN7gkhC4dyiRgmQiHn4NR6j8yMCnwP"
        >login</a
      >
    </ion-item>
  </form>

  <form [formGroup]="formGrpCreateGroup" (ngSubmit)="submitFormCreateGroup()">
    <h5>Create Group</h5>
    <ion-item>
      <ion-label position="floating">Group name</ion-label>

      <ion-input type="text" formControlName="groupName"></ion-input>
      <ion-button type="submit">submit</ion-button>
    </ion-item>
  </form>

  <form [formGroup]="formGrpAddMember" (ngSubmit)="submitFormAddMember()">
    <h5>Add Member to Group</h5>
    <ion-item>
      <ion-label position="floating">group_id*</ion-label>
      <ion-input type="text" formControlName="groupId"></ion-input>

      <ion-label position="floating">nickName*</ion-label>
      <ion-input type="text" formControlName="nickName"></ion-input>

      <ion-label position="floating">phone number</ion-label>
      <ion-input type="text" formControlName="phoneNumber"></ion-input>

      <ion-label position="floating">email</ion-label>
      <ion-input type="text" formControlName="email"></ion-input>

      <ion-label position="floating">user_id</ion-label>
      <ion-input type="text" formControlName="userId"></ion-input>
      <ion-button
        [disabled]="this.formGrpAddMember.value.groupId ===''"
        type="submit"
        >submit</ion-button
      >
    </ion-item>
  </form>

  <form [formGroup]="formGrpSendMsg" (ngSubmit)="submitFormSendMsg()">
    <h5>Send Message</h5>
    <ion-item>
      <ion-label position="floating">group_id*</ion-label>
      <ion-input type="text" formControlName="groupId"></ion-input>

      <ion-label position="floating">Message*</ion-label>
      <ion-input type="text" formControlName="msg"></ion-input>
      <ion-button
        [disabled]="this.formGrpAddMember.value.groupId ===''"
        type="submit"
        >submit</ion-button
      >
    </ion-item>
  </form>

  <form [formGroup]="formGrpGetMsgs" (ngSubmit)="submitFormGetMsgs()">
    <h5>Get Messages</h5>
    <ion-item>
      <ion-label position="floating">group_id*</ion-label>
      <ion-input type="text" formControlName="groupId"></ion-input>

      <ion-button
        [disabled]="this.formGrpAddMember.value.groupId ===''"
        type="submit"
        >submit</ion-button
      >
    </ion-item>
    <span *ngFor="let message of messages; let i = index"
      >{{message.name}}- {{message.text}}
      <span *ngIf="message.favorited_by?.length !== 0">
        [liked by {{message.favorited_by}}]</span
      ><br
    /></span>
  </form>

  {{result}}
</ion-content>

<!-- text support testing -->
<!-- <ion-item>
      <ion-label style="font-weight: bold" position="floating"
        >Number</ion-label
      >
      <ion-input type="text" formControlName="number"></ion-input>
    </ion-item>
    <ion-item style="width: 96%">
      <ion-label position="floating">Date test</ion-label>
      <br />
      <ct-date-selector-v2
        [props]="{
          formControl: formGrp.controls.startDate,
          options: dateTimeOptions
        }"
      ></ct-date-selector-v2>
      <app-test-component></app-test-component>

    </ion-item> -->

// Angular Modules
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Storage } from "@ionic/storage";

@Injectable({
  providedIn: "root",
})
export class HelpersService {
  constructor(public storage: Storage) {}

  setStorage(name: string, value: string): Promise<any> {
    console.log(`calling setStorage(${name}, ${value})`);
    return this.storage.set(name, value);
  }

  getStorage(name: string): Promise<string> {
    console.log(`calling getStorage('${name}')`);
    return this.storage.get(name).then((value) => {
      console.log(`getStorage(${name}) returned value:`, value);
      return value;
    });
  }

  removeStorage(name: string): Promise<any> {
    console.log(`removeStorage(${name})`);
    return this.storage.remove(name);
  }
}

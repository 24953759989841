import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot } from "@angular/router";
import { UserData } from "../providers/user-data";

@Injectable({
  providedIn: "root",
})

//https://www.joshmorony.com/prevent-access-to-pages-in-ionic-with-angular-route-guards/
export class AuthGuardService {
  public authInfo: boolean = false; // TODO: need to read from auth service.

  constructor(private router: Router, public userData: UserData) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    console.log("canActivate route: ", route.routeConfig);

    this.userData.isLoggedIn().then((loggedIn) => {
      if (loggedIn == true) {
        console.log("User is logged in.");
      } else {
        console.log("User is NOT logged in.. redirecting to login page.");
        this.router.navigate(["login"]);
      }
    });

    return true;
  }
}

import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { IonicModule } from "@ionic/angular";
import { IonicStorageModule } from "@ionic/storage";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DatePipe } from "@angular/common";

import { AngularFireModule } from "@angular/fire/compat";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { TestModule } from "./pages/test/test.module";
import { CtDatePipe } from "./shared/pipes/ct-date.pipe";
import { SharedModule } from "./shared/shared.module";
import { CreateScheduleModule } from "./pages/create-schedule/create-schedule.module";
import { CtDateTimeComponent } from "./shared/ct-date-selector/ct-date-time/ct-date-time.component";
import { EditScheduleModule } from "./pages/edit-schedule/edit-schedule.module";
import { GroupMeHttpService } from "./services/groupMe.service";
import { HelpersService } from "./services/helpers.service";
import { CreateLocationModule } from "./pages/create-location/create-location.module";

@NgModule({
  imports: [
    SharedModule,
    CreateScheduleModule,
    CreateLocationModule,
    EditScheduleModule,
    TestModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
  ],
  declarations: [AppComponent, CtDateTimeComponent],
  providers: [InAppBrowser, SplashScreen, StatusBar, DatePipe, CtDatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}

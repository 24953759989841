import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

// If date is minDate then return empty other wise format with datePipe to given format
@Pipe({
  name: "ctDate",
})
export class CtDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}
  transform(value: any, ...args: any[]): any {
    if (!value || value == "0001-01-01T00:00:00") return "";
    const format = args[0] ? args[0] : "MM/dd/yyyy hh:mm a";
    return value == "MM/dd/yyyy hh:mm a"
      ? ""
      : this.datePipe.transform(value, format);
  }
}

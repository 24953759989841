import {
  CtDateTimeComponent,
  DateTimeOptions,
} from "./ct-date-time/ct-date-time.component";
import { PopoverController } from "@ionic/angular";
import { Component, Input, OnInit } from "@angular/core";
import { CtDatePipe } from ".././pipes/ct-date.pipe";

@Component({
  selector: "ct-date-selector-v2",
  templateUrl: "./ct-date-selector.component.html",
  styleUrls: ["./ct-date-selector.component.scss"],
})
export class CtDateSelectorComponent implements OnInit {
  model: string;
  @Input() props: {
    formControl: any;
    options: DateTimeOptions;
  };

  get format() {
    return this.props.options.format
      ? this.props.options.format
      : "MM/dd/yyyy hh:mm a";
  }

  constructor(
    private PopoverController: PopoverController,
    private CtDatePipe: CtDatePipe
  ) {}

  ngOnInit(): void {
    if (this.props.formControl.value == "0001-01-01T00:00:00") {
      this.props.formControl.setValue(null);
    }
    if (!this.props.options) {
      this.props.options = new DateTimeOptions();
    }
    if (this.props.formControl) {
      this.model = this.CtDatePipe.transform(
        this.props.formControl.value,
        this.format
      );
    } else if (this.props.options.value) {
      this.model = this.CtDatePipe.transform(
        this.props.options.value,
        this.format
      );
    }
  }

  async onInputClick(ev) {
    if (this.props && this.props.formControl) {
      this.props.options.value = this.props.formControl.value;
    }

    const popover = await this.PopoverController.create({
      component: CtDateTimeComponent,
      event: ev,
      componentProps: {
        options: this.props.options,
      },
    });

    popover.onDidDismiss().then((res) => {
      if (this.props.formControl) {
        if (res.data) {
          this.props.formControl.setValue(res.data);
          this.props.options.value = res.data;
          this.model = this.CtDatePipe.transform(res.data, this.format);
        }
      }
    });

    return await popover.present();
  }
}

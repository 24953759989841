<ion-content>
  <form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="submitForm()">
    <ion-item>
      <ion-label position="floating">*Title</ion-label>
      <ion-input type="text" formControlName="title"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label
        [ngStyle]="{
          color: formGroup.controls.eventDate.valid === false ? 'red' : 'black'
        }"
        position="floating"
        >*Date/Time of Event</ion-label
      >
      <ct-date-selector-v2
        [props]="{
          formControl: formGroup.controls.eventDate,
          options: dateTimeOptions
        }"
      ></ct-date-selector-v2>
      <!-- <ion-datetime formControlName="eventDate"></ion-datetime> -->
    </ion-item>
    <ion-item>
      <ion-row>
        <ion-col class="ion-justify-content-start">
          <ion-label position="floating">*Hours</ion-label>
          <ion-input type="number" formControlName="hours"></ion-input>
        </ion-col>
        <ion-col class="ion-justify-content-start">
          <ion-label position="floating">Minutes</ion-label>
          <ion-input type="number" formControlName="minutes"></ion-input>
        </ion-col>
      </ion-row>
    </ion-item>
    <ion-item>
      <ion-label position="floating">*Location</ion-label>
      <ion-select formControlName="location">
        <ion-select-option
          *ngFor="let location of this._allData.data.locations"
          value="{{ location.id }}"
          >{{ location.name }}</ion-select-option
        >
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-label position="floating">*Reserved?</ion-label>
      <ion-select formControlName="reserved">
        <ion-select-option value="Open Play"
          >No reservation/Open Play</ion-select-option
        >
        <ion-select-option value="Reserved">Reserved/General</ion-select-option>
        <ion-select-option value="North">North</ion-select-option>
        <ion-select-option value="South">South</ion-select-option>
        <ion-select-option value="East">East</ion-select-option>
        <ion-select-option value="West">West</ion-select-option>
        <ion-select-option value="Temp Nets on Tennis Courts"
          >Temp nets on Tennis Courts</ion-select-option
        >
        <ion-select-option value="Court 1">Court 1</ion-select-option>
        <ion-select-option value="Court 2">Court 2</ion-select-option>
        <ion-select-option value="Court 3">Court 3</ion-select-option>
        <ion-select-option value="Court 4">Court 4</ion-select-option>
        <ion-select-option value="Court 5">Court 5</ion-select-option>
        <ion-select-option value="Court 6">Court 6</ion-select-option>
        <ion-select-option value="Court 7">Court 7</ion-select-option>
        <ion-select-option value="Court 8">Court 8</ion-select-option>
      </ion-select>
    </ion-item>

    <ion-item style="padding-top: 15px">
      <ion-label position="floating"
        >Participants (click to invite others)</ion-label
      >
      <ion-select multiple="true" formControlName="participants">
        <ion-select-option
          *ngFor="let participant of this._allData.data.participants"
          value="{{ participant.id }}"
          >{{ participant.name }}</ion-select-option
        >
      </ion-select>
    </ion-item>

    <ion-item style="margin-top: 15px">
      <ion-row>
        <ion-col>
          <ion-label position="floating">Limit total players</ion-label>
          <ion-select
            style="max-width: 100% !important"
            formControlName="limitTotalPlayers"
          >
            <ion-select-option value="">No limit</ion-select-option>
            <ion-select-option value="2">2</ion-select-option>
            <ion-select-option value="4">4</ion-select-option>
            <ion-select-option value="5">5</ion-select-option>
            <ion-select-option value="6">6</ion-select-option>
            <ion-select-option value="7">7</ion-select-option>
            <ion-select-option value="8">8</ion-select-option>
            <ion-select-option value="8">12</ion-select-option>
            <ion-select-option value="8">16</ion-select-option>
            <ion-select-option value="8">20</ion-select-option>
            <ion-select-option value="8">24</ion-select-option>
          </ion-select>
        </ion-col>
        <ion-col>
          <ion-label position="floating">Private (invited only)</ion-label>
          <ion-toggle formControlName="private"></ion-toggle>
        </ion-col>
      </ion-row>
    </ion-item>

    <ion-item style="padding-top: 10px" lines="none">
      <ion-button
        style="padding-left: 20px"
        type="submit"
        [disabled]="!formGroup.valid"
      >
        <ion-label>Save</ion-label>
      </ion-button>
      <ion-button (click)="onClose()">
        <ion-label>Cancel</ion-label>
      </ion-button>
    </ion-item>
  </form>
</ion-content>

import { DatePipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ModalController, ToastController } from "@ionic/angular";
import { getDatabase, ref, push, set } from "firebase/database";
import { TextServices } from "../../providers/textServices";
import { ThePickleBawlersData } from "../../providers/thepicklebawlers-data";
import { UserData } from "../../providers/user-data";
import { DateTimeOptions } from "../../shared/ct-date-selector/ct-date-time/ct-date-time.component";
import { CtDatePipe } from "../../shared/pipes/ct-date.pipe";
import { ParticipantListModule } from "../participant-list/participant-list.module";

@Component({
  selector: "ct-create-schedule",
  templateUrl: "./create-schedule.component.html",
  styleUrls: ["./create-schedule.component.scss"],
})
export class CreateScheduleComponent implements OnInit {
  dateTimeOptions: DateTimeOptions = new DateTimeOptions();

  public _allData: any;

  @Input() set allData(data: any) {
    this._allData = data;
    this._allData.data.participants.sort((n1, n2) => {
      if (n1.name > n2.name) {
        return 1;
      }

      if (n1.name < n2.name) {
        return -1;
      }

      return 0;
    });
    this._allData.data.locations.sort((n1, n2) => {
      if (n1.name > n2.name) {
        return 1;
      }

      if (n1.name < n2.name) {
        return -1;
      }

      return 0;
    });

    this.userData.getUsername().then(async (username) => {
      this._allData.data.participants.find((participant: any) => {
        if (participant.email.toLowerCase() == username.toLocaleLowerCase()) {
          this.userName = {
            name: participant.name,
            id: participant.id,
            email: participant.email,
          };

          let now = new Date();
          now.setHours(now.getHours() - 1); // force them to enter.
          now.setMinutes(0);

          let todayFormatted: string =
            this.datePipe.transform(now, "yyyy-MM-ddTHH:mm:ss") + "Z";

          this.formGroup = this.formBuilder.group({
            title: ["", Validators.required],
            hours: ["", Validators.required],
            minutes: [""],
            eventDate: [undefined, Validators.required],
            //eventDate: new FormControl(undefined, Validators.required),
            location: ["", Validators.required],
            reserved: ["", Validators.required],
            participants: [[this.userName.id], Validators.required],
            limitTotalPlayers: [""],
            private: false,
          });
        }
      });
    });
  }

  public formGroup: FormGroup;
  public defaultEventDate = new Date();
  public userName: any;

  /**
   * Constructor
   * @param dlg
   * @param globalSearchService
   */
  constructor(
    private dlg: ModalController,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    public toastCtrl: ToastController,
    public thePickleBawlersData: ThePickleBawlersData,
    public userData: UserData,
    public textServices: TextServices,
    public confData: ThePickleBawlersData,
    public ctDatePipe: CtDatePipe
  ) {}

  ngOnInit(): void {
    console.log("this.dateTimeOptions", this.dateTimeOptions);
    this.dateTimeOptions.presentation = "date-time";
    this.dateTimeOptions.size = "cover";

    let now = new Date();
    now.setHours(now.getHours() - 1);
    now.setMinutes(0);

    this.dateTimeOptions.min = this.ctDatePipe.transform(
      now,
      "yyyy-MM-ddTHH:mm:ss"
    );

    console.log("this.dateTimeOptions after:", this.dateTimeOptions);
  }

  //
  // submitForm()
  //
  //
  async submitForm() {
    let eventDate = new Date(this.formGroup.value.eventDate);
    let eventDateString = String(this.formGroup.value.eventDate).substring(
      0,
      10
    );

    let eventTimeString: string = this.datePipe.transform(eventDate, "h:mm a");

    let eventEndDateString = eventDate;
    eventEndDateString.setHours(
      eventDate.getHours() + this.formGroup.value.hours
    );
    eventEndDateString.setMinutes(
      eventDate.getMinutes() + this.formGroup.value.minutes
    );

    let eventEndTimeString: string = this.datePipe.transform(
      eventEndDateString,
      "h:mm a"
    );

    const location = this._allData.data.locations.find(
      (l: any) => l.id == this.formGroup.value.location
    );

    let participantsNotificatonsList = [];
    this._allData.data.participants.forEach((participant: any) => {
      if (participant?.txtMeAt) {
        let phone = participant.phone.replace(/\D/g, "");
        console.log("phone stripped: ", phone);
        participantsNotificatonsList.push({
          name: participant.name,
          at: phone + participant.txtMeAt,
        });
      }
    });

    // participants list
    //
    let participantsList = [];
    let iOffSet: number = 0;
    this.formGroup.value.participants.forEach((participant: any) => {
      // check if this session should show or not
      this._allData.data.participants.find((p: any) => {
        if (p.id == participant) {
          participantsList.push({
            id: iOffSet++, // not p.id of User, yet offset so we can find for updates/etc in firebase
            name: p.name,
            confirmed: this.userName.id == p.id ? "yes" : "",
          });
        }
      });
    });

    this.thePickleBawlersData
      .getAllSchedules()
      .subscribe(async (events: any) => {
        let nextIndex = events == null ? 0 : events.length;
        let Goo: boolean = true;
        if (Goo) {
          const db = getDatabase();

          // NOTE:
          // Can only add 1.  if adding more.. need to add just group; not entire schedule!!
          //
          //
          try {
            set(
              ref(db, "thePickleBawlersOfJax/events/" + nextIndex),

              {
                group: "thePickleBawlersOfJax",
                id: `${nextIndex}`,
                date: `${eventDateString}`,
                createdBy: {
                  id: this.userName.id,
                  email: `${this.userName.email}`,
                  by: `${this.userName.name}`,
                  on: `${eventDateString}`,
                },
                name: this.formGroup.value.title,
                location: {
                  name: `${location.name}`,
                  id: location.id,
                },
                court: `${this.formGroup.value.reserved}`,
                description: "",
                participants: participantsList,
                timeStart: `${eventTimeString}`,
                timeEnd: `${eventEndTimeString}`,
                type: "PickleBall",
                frequency: "",
                limitTotalPlayers: `${this.formGroup.value.limitTotalPlayers}`,
                private: `${this.formGroup.value.private}`,
              }
            );
          } catch (error) {
            console.error("error", error);
            const toast = await this.toastCtrl.create({
              message: "failed - " + error,
              duration: 2000,
              position: "middle",
              color: "danger",
              keyboardClose: true,
            });
            await toast.present();
            this.dlg.dismiss();
            return;
          }

          const toast = await this.toastCtrl.create({
            message: "Schedule created...",
            duration: 2000,
            position: "middle",
            color: "success",
            keyboardClose: true,
          });
          await toast.present();

          setTimeout(() => {
            this.confData.getTimeline(0).subscribe((events: any) => {
              if (events) {
              }
            });
          }, 300);

          try {
            this.textServices.onText(
              participantsNotificatonsList,
              "",
              "Event Created",
              `Event created for ${location.name} on ${eventDateString} @ ${eventTimeString} `
            );
          } catch (error) {
            console.error("error calling onText(): ", error);
          }

          //  i was expecting this to refresh .. it didn't.. TODO: refresh
          //this.thePickleBawlersData.getTimeline(0).subscribe((data: any) => {});
        }
      });

    this.dlg.dismiss();
  }

  onClose() {
    this.dlg.dismiss();
  }

  /**
   * Returns search observable
   * @returns { Observable<any> }
   */
  doSearch() {
    console.log("todo");
  }
}

<ion-datetime
  #datetime
  [value]="options.value"
  [size]="options.size"
  [min]="options.min"
  [max]="options.max"
  [presentation]="options.presentation"
>
</ion-datetime>
<div class="hr"></div>
<ion-toolbar>
  <ion-buttons slot="end">
    <ion-button color="primary" (click)="onDone(datetime?.value)">
      <span>Done</span>
    </ion-button>
    <ion-button color="danger" (click)="onCancel()">
      <span>Cancel</span>
    </ion-button>
  </ion-buttons>
</ion-toolbar>

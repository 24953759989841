import { DatePipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalController, ToastController } from "@ionic/angular";
import { getDatabase, ref, push, set } from "firebase/database";
import { TextServices } from "../../providers/textServices";
import { ThePickleBawlersData } from "../../providers/thepicklebawlers-data";
import { UserData } from "../../providers/user-data";
import { ParticipantListModule } from "../participant-list/participant-list.module";

@Component({
  selector: "ct-edit-schedule",
  templateUrl: "./edit-schedule.component.html",
  styleUrls: ["./edit-schedule.component.scss"],
})
export class EditScheduleComponent {
  private _eventId: any;
  @Input() set eventId(value: any) {
    this._eventId = value;
  }
  get eventId() {
    return this._eventId;
  }

  public _allData: any;

  @Input() set allData(data: any) {
    this._allData = data;

    this.userData.getUsername().then(async (username) => {
      this._allData.data.participants.find((participant: any) => {
        if (participant.email.toLowerCase() == username.toLocaleLowerCase()) {
          this.userName = {
            name: participant.name,
            id: participant.id,
          };
        }
      });
    });

    let dateStart =
      this._allData.data.events[this.eventId].date +
      " " +
      this._allData.data.events[this.eventId].timeStart;

    let dateEnd =
      this._allData.data.events[this.eventId].date +
      " " +
      this._allData.data.events[this.eventId].timeEnd;

    let dateStartFormatted: string =
      this.datePipe.transform(dateStart, "yyyy-MM-ddTHH:mm:ss") + "Z";

    let dateEndFormatted: string =
      this.datePipe.transform(dateEnd, "yyyy-MM-ddTHH:mm:ss") + "Z";

    let startAsDate: Date = new Date(dateStartFormatted);
    let endAsDate: Date = new Date(dateEndFormatted);

    var diff = endAsDate.getTime() - startAsDate.getTime();
    diff = diff / (1000 * 60 * 60);
    var hoursDiff = Math.floor(diff);
    let minutesDiff = endAsDate.getMinutes();

    this.formGroup = this.formBuilder.group({
      title: [
        this._allData.data.events[this.eventId].name,
        Validators.required,
      ],
      hours: [hoursDiff, Validators.required],
      minutes: [minutesDiff],
      eventDate: [dateStartFormatted, Validators.required],
      location: ["3", Validators.required],
      reserved: ["East", Validators.required],
      participants: ["0", Validators.required],
    });

    //this.formGroup.controls.location.setValue("3");
  }

  public formGroup: FormGroup;
  public defaultEventDate = new Date();
  public userName: any;

  /**
   * Constructor
   * @param dlg
   * @param globalSearchService
   */
  constructor(
    private dlg: ModalController,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    public toastCtrl: ToastController,
    public thePickleBawlersData: ThePickleBawlersData,
    public userData: UserData,
    public textServices: TextServices,
    public confData: ThePickleBawlersData
  ) {}

  //
  // submitForm()
  //
  //
  async submitForm() {
    let eventDate = new Date(this.formGroup.value.eventDate);
    let eventDateString = String(this.formGroup.value.eventDate).substring(
      0,
      10
    );

    let eventTimeString: string = this.datePipe.transform(eventDate, "h:mm a");

    let eventEndDateString = eventDate;
    eventEndDateString.setHours(
      eventDate.getHours() + this.formGroup.value.hours
    );
    eventEndDateString.setMinutes(
      eventDate.getMinutes() + this.formGroup.value.minutes
    );

    let eventEndTimeString: string = this.datePipe.transform(
      eventEndDateString,
      "h:mm a"
    );

    const location = this._allData.data.locations.find(
      (l: any) => l.id == this.formGroup.value.location
    );

    let participantsNotificatonsList = [];
    this._allData.data.participants.forEach((participant: any) => {
      if (participant?.txtMeAt) {
        let phone = participant.phone.replace(/\D/g, "");
        console.log("phone stripped: ", phone);
        participantsNotificatonsList.push({
          name: participant.name,
          at: phone + participant.txtMeAt,
        });
      }
    });

    // participants list
    //
    let participantsList = [];
    let iOffSet: number = 0;
    this.formGroup.value.participants.forEach((participant: any) => {
      // check if this session should show or not
      this._allData.data.participants.find((p: any) => {
        if (p.id == participant) {
          participantsList.push({
            id: iOffSet++, // not p.id of User, yet offset so we can find for updates/etc in firebase
            name: p.name,
            confirmed: this.userName.id == p.id ? "yes" : "",
          });
        }
      });
    });

    const db = getDatabase();

    // NOTE:
    // Can only add 1.  if adding more.. need to add just group; not entire schedule!!
    //
    //
    try {
      set(
        ref(db, "thePickleBawlersOfJax/events/" + this.eventId),

        {
          group: "thePickleBawlersOfJax",
          id: `${this.eventId}`,
          date: `${eventDateString}`,
          createdBy: {
            id: this.userName.id,
            by: `${this.userName.name}`,
            on: `${eventDateString}`,
          },
          name: this.formGroup.value.title,
          location: {
            name: `${location.name}`,
            id: location.id,
          },
          court: `${this.formGroup.value.reserved}`,
          description: "",
          participants: participantsList,
          timeStart: `${eventTimeString}`,
          timeEnd: `${eventEndTimeString}`,
          type: "PickleBall",
          frequency: "",
        }
      );
    } catch (error) {
      console.error("error", error);
      const toast = await this.toastCtrl.create({
        message: "failed - " + error,
        duration: 2000,
        position: "top",
        color: "danger",
      });
      await toast.present();
      this.dlg.dismiss();
      return;
    }

    const toast = await this.toastCtrl.create({
      message: "it worked!",
      duration: 2000,
      position: "top",
      color: "success",
    });
    await toast.present();

    setTimeout(() => {
      this.confData.getTimeline(0).subscribe((events: any) => {
        if (events) {
        }
      });
    }, 300);

    try {
      this.textServices.onText(
        participantsNotificatonsList,
        "",
        "Event Created",
        `Event created for ${location.name} on ${eventDateString} @ ${eventTimeString} `
      );
    } catch (error) {
      console.error("error calling onText(): ", error);
    }

    this.dlg.dismiss();
  }

  onClose() {
    this.dlg.dismiss();
  }

  /**
   * Returns search observable
   * @returns { Observable<any> }
   */
  doSearch() {
    console.log("todo");
  }
}

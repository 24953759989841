<ion-content>
  <form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="submitForm()">
    <ion-item>
      <ion-label style="font-weight: bold" position="floating"
        >*Title</ion-label
      >
      <ion-input type="text" formControlName="title"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label style="font-weight: bold" position="floating"
        >*Date of Event</ion-label
      ><br />
      <ion-datetime formControlName="eventDate"></ion-datetime>
    </ion-item>
    <ion-item>
      <br />
      <div style="font-weight: bold">Details</div>
      <ion-label position="floating">*Hours</ion-label>
      <ion-input type="number" formControlName="hours"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label position="floating">Minutes</ion-label>
      <ion-input type="number" formControlName="minutes"></ion-input>
    </ion-item>
    <ion-item *ngIf="this._allData.data.locations">
      <ion-label position="floating">*Location</ion-label>
      <ion-select formControlName="location">
        <ion-select-option
          *ngFor="let location of this._allData.data.locations"
          value="{{ location.id }}"
          >{{ location.name }}</ion-select-option
        >
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-label position="floating">*Reserved?</ion-label>
      <ion-select formControlName="reserved">
        <ion-select-option value="Open Play"
          >No reservation/Open Play</ion-select-option
        >
        <ion-select-option value="Reserved">Reserved/General</ion-select-option>
        <ion-select-option value="North">North</ion-select-option>
        <ion-select-option value="South">South</ion-select-option>
        <ion-select-option value="East">East</ion-select-option>
        <ion-select-option value="West">West</ion-select-option>
        <ion-select-option value="Court 1">Court 1</ion-select-option>
        <ion-select-option value="Court 2">Court 2</ion-select-option>
        <ion-select-option value="Court 3">Court 3</ion-select-option>
        <ion-select-option value="Court 4">Court 4</ion-select-option>
        <ion-select-option value="Court 5">Court 5</ion-select-option>
        <ion-select-option value="Court 6">Court 6</ion-select-option>
        <ion-select-option value="Court 7">Court 7</ion-select-option>
        <ion-select-option value="Court 8">Court 8</ion-select-option>
      </ion-select>
    </ion-item>

    <ion-item>
      <ion-item *ngIf="this._allData.data.participants">
        <ion-label position="floating">Particpants</ion-label>
        <ion-select multiple="true" formControlName="participants">
          <ion-select-option
            *ngFor="let participant of this._allData.data.participants"
            value="{{ participant.id }}"
            >{{ participant.name }}</ion-select-option
          >
        </ion-select>
      </ion-item>
    </ion-item>
    <ion-item>
      <ion-button
        style="padding-left: 20px"
        type="submit"
        [disabled]="!formGroup.valid"
      >
        <ion-label>Save</ion-label>
      </ion-button>
      <ion-button (click)="onClose()">
        <ion-label>Cancel</ion-label>
      </ion-button>
    </ion-item>
  </form>
</ion-content>

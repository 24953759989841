import { DatePipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ModalController, ToastController } from "@ionic/angular";
import { getDatabase, ref, push, set } from "firebase/database";
import { TextServices } from "../../providers/textServices";
import { ThePickleBawlersData } from "../../providers/thepicklebawlers-data";
import { UserData } from "../../providers/user-data";
import { CtDatePipe } from "../../shared/pipes/ct-date.pipe";

@Component({
  selector: "ct-create-location",
  templateUrl: "./create-location.component.html",
  styleUrls: ["./create-location.component.scss"],
})
export class CreateLocationComponent {
  public _allData: any;

  @Input() set allData(data: any) {
    this._allData = data;

    this.userData.getUsername().then(async (username) => {
      this._allData.data.participants.find((participant: any) => {
        if (participant.email.toLowerCase() == username.toLocaleLowerCase()) {
          this.userName = {
            name: participant.name,
            id: participant.id,
          };

          let now = new Date();

          let todayFormatted: string =
            this.datePipe.transform(now, "yyyy-MM-ddTHH:mm:ss") + "Z";

          this.formGroup = this.formBuilder.group({
            title: ["", Validators.required],
          });
        }
      });
    });
  }

  public formGroup: FormGroup;
  public defaultEventDate = new Date();
  public userName: any;

  /**
   * Constructor
   * @param dlg
   * @param globalSearchService
   */
  constructor(
    private dlg: ModalController,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    public toastCtrl: ToastController,
    public thePickleBawlersData: ThePickleBawlersData,
    public userData: UserData,
    public textServices: TextServices,
    public confData: ThePickleBawlersData,
    public ctDatePipe: CtDatePipe
  ) {}

  //
  // submitForm()
  //
  //
  async submitForm() {
    this.thePickleBawlersData
      .getAllLocations()
      .subscribe(async (locations: any) => {
        let nextIndex = locations == null ? 0 : locations.length;
        let Goo: boolean = true;
        if (Goo) {
          const db = getDatabase();

          // NOTE:
          // Can only add 1.  if adding more.. need to add just group; not entire schedule!!
          //
          //
          try {
            set(ref(db, "thePickleBawlersOfJax/locations/" + nextIndex), {
              city: "city",
              courtCount: 4,
              deleted: false,
              hasReservations: false,
              id: 100,
              name: "location Name",
              notes: "no notes",
              phone: "904-555-1212",
              state: "Fl",
              street: "1 test street",
              website: "http://thepicklebawlers.com",
              zip: "32256",
              private: false,
            });
          } catch (error) {
            console.error("error", error);
            const toast = await this.toastCtrl.create({
              message: "failed - " + error,
              duration: 2000,
              position: "middle",
              color: "danger",
              keyboardClose: true,
            });
            await toast.present();
            this.dlg.dismiss();
            return;
          }

          const toast = await this.toastCtrl.create({
            message: "Location created...",
            duration: 2000,
            position: "middle",
            color: "success",
            keyboardClose: true,
          });
          await toast.present();
        }
      });

    this.dlg.dismiss();
  }

  onClose() {
    this.dlg.dismiss();
  }

  /**
   * Returns search observable
   * @returns { Observable<any> }
   */
  doSearch() {
    console.log("todo");
  }
}

import { PopoverController } from "@ionic/angular";
import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "ct-ct-date-time",
  templateUrl: "./ct-date-time.component.html",
  styleUrls: ["./ct-date-time.component.scss"],
})
export class CtDateTimeComponent implements OnInit {
  constructor(private PopoverController: PopoverController) {}

  @Input() options: DateTimeOptions;

  /**
   * Dismiss modal with date time value
   * @param datetime
   */
  onDone(datetime: string) {
    this.PopoverController.dismiss(datetime);
  }

  /**
   * Dismiss modal with no value
   */
  onCancel() {
    this.PopoverController.dismiss();
  }

  /**
   * OnInit: set initial value as min if no value was provided
   */
  ngOnInit() {
    if (!this.options.value && this.options.min) {
      this.options.value = this.options.min;
    }
  }
}

export class DateTimeOptions {
  constructor(
    public cancelText?: string,
    public clearText?: string,
    public color?: string,
    public dayValues?: any,
    public disabled?: boolean,
    public doneText?: string,
    public firstDayOfWeek?: number,
    public hourCycle?: string,
    public hourValues?: any,
    public locale?: string,
    public max?: string,
    public min?: string,
    public minuteValues?: any,
    public mode?: string,
    public monthValues?: any,
    public name?: string,
    public presentation?: string,
    public readonly?: boolean,
    public showClearButton?: boolean,
    public showDefaultButtons?: boolean,
    public showDefaultTimeLabel?: boolean,
    public showDefaultTitle?: boolean,
    public size?: string,
    public value?: string,
    public yearValues?: any,
    public format?: string
  ) {
    if (!this.format) {
      this.format = "MM/dd/yyyy @ hh:mm a";
    }
    if (!this.presentation) {
      this.presentation = "date-time";
    }

    if (!this.size) {
      this.size = "cover";
    }
  }
}

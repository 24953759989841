import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TestComponentComponent } from "./test-component/test-component.component";
import { CtDateSelectorComponent } from "./ct-date-selector/ct-date-selector.component";

@NgModule({
  declarations: [TestComponentComponent, CtDateSelectorComponent],
  imports: [CommonModule],
  exports: [TestComponentComponent, CtDateSelectorComponent],
  providers: [],
})
export class SharedModule {}

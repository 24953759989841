import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { TestPage } from "./test";
import { TestPageRoutingModule } from "./test-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "../../shared/shared.module";
import { CtDateSelectorComponent } from "../../shared/ct-date-selector/ct-date-selector.component";

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    IonicModule,
    TestPageRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    TestPage,
    //TestComponentComponent,
    // CtDateTimeComponent,
    //CtDateSelectorComponent,
  ],
})
export class TestModule {}
